import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import { BootstrapVueIcons } from "bootstrap-vue";
import router from "./router";
import VueCookies from "vue-cookies";

Vue.config.productionTip = false;

Vue.use(VueCookies);
Vue.use(BootstrapVueIcons);

if (
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1"
) {
  console.debug("Using development API endpoint");
  window.api_endpoint = "http://127.0.0.1:3000";
} else {
  console.debug("Using production API endpoint");
  window.api_endpoint = "https://voter.is-coding-on-the.net";
}

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <div id="app" class="m-2">
    <b-navbar toggleable="lg" variant="faded" type="light">
      <b-navbar-brand tag="h1" class="mb-0">Voter WebUI</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item v-b-modal.set-apikey-modal>Settings &#38; Preferences</b-nav-item>
          <b-nav-item @click="addVoteModal">Add Vote</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-button to="bp" pill :variant="isInvalidBuild ? 'danger' : 'success'"
            v-show="runningBuildPatchStatus"><b-icon-tools /> Build Patch: {{ runningBuildPatch }}</b-button>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <b-modal id="delete-vote-prompt-modal" title="Delete">
      You are about to delete the <b>{{ editing.vote_id }}</b> vote. This <b>can not be undone</b>. Are you sure you
      want
      to do this?
      <div slot="modal-footer">
        <b-row align-h="center" align-v="center">
          <b-col cols="auto">
            <b-form-checkbox v-model="understandDeleting" :value="true" :unchecked-value="false" plain>I understand the
              consequences</b-form-checkbox>
          </b-col>
          <b-col>
            <b-button variant="danger" @click="apiCallDelete" :disabled="!understandDeleting">
              <b-icon-trash-fill /> Delete Vote
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal id="set-apikey-modal" title="Settings & Preferences" @ok="setSettings" size="lg" ok-title="Save Changes">
      <form ref="form" @submit.stop.prevent="setSettings">
        <b-form-group>
          <template #label>API Key</template>
          <b-form-input v-model="apiKey" />
        </b-form-group>
        <b-form-group>
          <template #label>Default Webhook</template>
          <b-form-input v-model="defaultWebhook" />
        </b-form-group>
        <b-form-group>
          <template #label>Default Button</template>
          <b-card>
            <b-card-title>
              Button
            </b-card-title>
            <b-row>
              <b-col>
                <b-card-text>Custom ID</b-card-text>
                <b-form-input disabled lazy-formatter :formatter="buttonCustomIdFormatter"
                  v-model="defaultButton.custom_id" />
                <b-form-text>(Internal use) tracks what is being voted and can be shown on
                  <code>Incoming Casted Votes</code>
                  depending on <code>Anonymize Responses</code></b-form-text>
              </b-col>
              <b-col>
                <b-card-text>Label</b-card-text>
                <b-form-input v-model="defaultButton.label" />
                <b-form-text>Text to be shown on button (1-80 characters)</b-form-text>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-card-text>Inital Voted Message</b-card-text>
                <b-form-input v-model="defaultButton.message" />
                <b-form-text>This message is shown when a user intially votes</b-form-text>
              </b-col>
              <b-col>
                <b-card-text>Already Voted Message</b-card-text>
                <b-form-input v-model="defaultButton.alreadyVotedMessage" />
                <b-form-text>This message is shown when a user attempts to vote after already doing so</b-form-text>
              </b-col>
            </b-row>
          </b-card>
        </b-form-group>
      </form>
    </b-modal>

    <b-modal id="edit-vote-modal" size="lg" :title="`${editingState == 'EDITING' ? 'Edit' : 'Add'} Vote`" scrollable
      @ok="handleSubmit" @cancel="resetEntry" @exit="resetEntry">
      <form ref="form" @submit.stop.prevent="handleSubmit">

        <b-form-group>
          <b-form-checkbox v-model="editing.active" :value="true" :unchecked-value="false"
            switch>Active</b-form-checkbox>
          <b-form-text>Whether or not votes can be cast</b-form-text>
        </b-form-group>

        <b-form-group>
          <template #label>Guild ID</template>
          <b-form-input v-model="editing.guild_id" />
          <b-form-text>This is the guild ID that the vote is associated with</b-form-text>
          <b-button @click="editing.guild_id = searchTermGuildId ||
            localStorage.getItem('LAST_SEARCH_GUILD_ID')">
            Use Last Guild ID <b-icon-arrow-counterclockwise />
          </b-button>
        </b-form-group>

        <b-form-group label-for="id-input">
          <template #label>Vote ID</template>
          <b-form-input lazy-formatter :formatter="voteIdFormatter" v-model="editing.vote_id"
            :disabled="editingState == 'EDITING'" id="id-input"></b-form-input>
          <b-form-text>You use this to start a vote using <code>/start vote_id:[...]</code></b-form-text>
          <b-form-text v-show="editingState == 'EDITING'">This value cannot be changed.</b-form-text>
        </b-form-group>

        <b-form-group label-for="newvote-input">
          <template #label>
            Buttons
            <b-button :disabled="editing.buttons.length >= 5" variant="success"
              @click="addNewButton"><b-icon-plus-circle-fill /> Add</b-button>
          </template>
          <b-card v-for="(button, index) in editing.buttons" :key="button.id" class="mb-2">
            <b-card-title>
              Button {{ index + 1 }}
              <b-button class="ml-2" variant="danger" @click="removeButton(index)"><b-icon-x-circle-fill />
                Remove</b-button>
              <b-button class="ml-2" variant="secondary" @click="useDefaultButton(index)"
                :disabled="!defaultButton"><b-icon-clipboard /> Use Default</b-button>
            </b-card-title>
            <b-row>
              <b-col>
                <b-card-text>Custom ID</b-card-text>
                <b-form-input lazy-formatter :formatter="buttonCustomIdFormatter" v-model="button.custom_id" />
                <b-form-text>(Internal use) tracks what is being voted and can be shown on <code>Incoming Casted
                Votes</code> depending on <code>Anonymize Responses</code></b-form-text>
              </b-col>
              <b-col>
                <b-card-text>Label</b-card-text>
                <b-form-input v-model="button.label" />
                <b-form-text>Text to be shown on button (1-80 characters)</b-form-text>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-card-text>Inital Voted Message</b-card-text>
                <b-form-input v-model="button.message" />
                <b-form-text>This message is shown when a user intially votes</b-form-text>
              </b-col>
              <b-col>
                <b-card-text>Already Voted Message</b-card-text>
                <b-form-input v-model="button.alreadyVotedMessage" />
                <b-form-text>This message is shown when a user attempts to vote after already doing so</b-form-text>
              </b-col>
            </b-row>
          </b-card>

        </b-form-group>

        <b-form-group>
          <template #label>Incoming Casted Votes (Discord Webhook)
            <b-button :disabled="!defaultWebhook" variant="secondary" @click="useDefaultWebhookUrl"><b-icon-clipboard />
              Use Default</b-button>
          </template>
          <b-form-input v-model="editing.discord_webhook"></b-form-input>
          <b-form-text>Cast votes are logged here</b-form-text>
        </b-form-group>

        <b-form-group>
          <template #label>Initial Message</template>
          <b-form-textarea v-model="editing.initialMessage" rows="4" max-rows="6"></b-form-textarea>
          <b-form-text>This is the message that is shown when <code>/start vote_id:[...]</code> is ran</b-form-text>
        </b-form-group>

        <b-form-group>
          <b-form-checkbox v-model="editing.anonymizeResponses" :value="true" :unchecked-value="false" switch>Anonymize
            Responses</b-form-checkbox>
          <b-form-text>When a vote is cast, the <code>Incoming Casted Votes</code> webhook URL will only show that a
            user
            cast a vote, but not <i>what</i> their vote is.</b-form-text>
          <b-form-checkbox v-model="editing.anonymizeResults" :value="true" :unchecked-value="false" switch>Anonymize
            Results</b-form-checkbox>
          <b-form-text>When <code>/results vote_id:[...]</code> is ran, it'll show the amount of cast votes but not
            <i>what</i> the votes are.</b-form-text>
        </b-form-group>
      </form>
      <b-button v-show="editingState == 'EDITING'" v-b-tooltip.hover title="This cannot be undone!" variant="danger"
        v-b-modal.delete-vote-prompt-modal>
        <b-icon-trash-fill /> Delete Vote
      </b-button>
      <div slot="modal-footer">
        <span class="mr-2"></span>
        <b-button variant="success" @click="handleSubmit">
          <b-icon :icon="editingState == 'EDITING' ? 'download' : 'plus-circle-fill'" /> {{ editingState == 'EDITING' ?
            'Save Changes' : 'Create' }}
        </b-button>
      </div>
    </b-modal>

    <b-row align-h="center" class="mb-1">
      <b-col cols="auto" />
      <b-col cols="8">
        <b-form @submit="apiCallGet">
          <b-input-group>
            <b-form-input v-model="searchTermGuildId" placeholder="Enter the guild ID to manage" @kenter="apiCallGet"
              v-on:submit.prevent="apiCallGet"></b-form-input>
            <b-button @click="apiCallGet">Submit</b-button>
          </b-input-group>
        </b-form>
      </b-col>
      <b-col cols="auto" />
    </b-row>
    <!-- <b-row class="w-75 mx-auto">
      <b-col cols="10">
        <b-form-input v-model="searchTerm" placeholder="Filter results using initalMessage or vote_id"></b-form-input>
      </b-col>
    </b-row> -->

    <b-pagination v-model="currentPage" :total-rows="currentItems.length" :per-page="perPage" pills align="center"
      limit="10" first-number last-number />
    <b-table class="mt-1" striped :per-page="perPage" :current-page="currentPage" :items="currentItems" :fields="fields"
      responsive="md">

      <template #cell(active)="data">
        <b-icon :icon="data.item.active == true ? 'check-circle-fill' : 'x-circle-fill'"
          :variant="data.item.active == true ? 'success' : 'danger'" />
      </template>

      <template #cell(anonymizeResponses)="data">
        <b-icon :icon="data.item.anonymizeResponses == true ? 'check-circle-fill' : 'x-circle-fill'"
          :variant="data.item.anonymizeResponses == true ? 'success' : 'danger'" />
      </template>

      <template #cell(anonymizeResults)="data">
        <b-icon :icon="data.item.anonymizeResults == true ? 'check-circle-fill' : 'x-circle-fill'"
          :variant="data.item.anonymizeResults == true ? 'success' : 'danger'" />
      </template>

      <template #cell(options)="row">
        <b-button v-b-tooltip.hover title="Modify Vote" size="sm" class="m-2" @click="modifyEntry(row.item)">
          <b-icon-pencil-fill />
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      invalidBuild: false,
      apiKey: "",
      defaultWebhook: "",
      defaultButton: {
        alreadyVotedMessage: "",
        custom_id: "",
        label: "",
        message: ""
      },

      fields: [
        'active',
        'voteCount',
        // 'buttons', 
        // 'discord_webhook', 
        // 'guild_id', 
        // 'initialMessage', 
        'vote_id',
        'anonymizeResponses',
        'anonymizeResults',
        "Options"
      ],
      items: [],
      currentPage: 1,
      perPage: 6,
      searchTerm: "",
      searchTermGuildId: "",
      blockInteraction: false,
      // "EDITING" || "NEW"
      editingState: "",
      editing: {
        'active': true,
        'voteCount': 0,
        'buttons': [],
        'discord_webhook': "",
        'guild_id': "",
        'initialMessage': "",
        'vote_id': "",
        'anonymizeResponses': false,
        'anonymizeResults': false
      },
      oldVote: {
        'active': true,
        'voteCount': 0,
        'buttons': [],
        'discord_webhook': "",
        'guild_id': "",
        'initialMessage': "",
        'vote_id': "",
        'anonymizeResponses': false,
        'anonymizeResults': false
      },
      understandDeleting: false
    }
  },
  methods: {
    async checkBuildPatch() {
      let res = await fetch(`/_patches/${this.runningBuildPatch}`, { "method": "GET" })
      if (res.status == 200) {
        this.invalidBuild = false
      } else {
        this.invalidBuild = true
      }
    },
    modifyChoice(choice) {
      console.log(choice)
    },
    modifyEntry(entry) {
      this.editing = {
        'active': entry.active,
        'voteCount': entry.voteCount,
        'buttons': entry.buttons,
        'discord_webhook': entry.discord_webhook,
        'guild_id': entry.guild_id,
        'initialMessage': entry.initialMessage,
        'vote_id': entry.vote_id,
        'anonymizeResponses': entry.anonymizeResponses,
        'anonymizeResults': entry.anonymizeResults
      };
      if (this.editing.vote_id != this.oldVote.vote_id)
        this.oldVote = {
          'active': entry.active,
          'voteCount': entry.voteCount,
          'buttons': entry.buttons,
          'discord_webhook': entry.discord_webhook,
          'guild_id': entry.guild_id,
          'initialMessage': entry.initialMessage,
          'vote_id': entry.vote_id,
          'anonymizeResponses': entry.anonymizeResponses,
          'anonymizeResults': entry.anonymizeResults
        };
      this.editingState = "EDITING"
      this.$bvModal.show("edit-vote-modal")
    },
    resetEntry() {
      this.editing = this.oldVote
    },
    useDefaultWebhookUrl() {
      this.editing.discord_webhook = this.defaultWebhook
    },
    addNewButton() {
      this.editing.buttons.push({
        "custom_id": "",
        "label": "",
        "message": "",
        "alreadyVotedMessage": ""
      })
    },
    removeButton(index) {
      var removed = this.editing.buttons.splice(index, 1);
      removed;
    },
    useDefaultButton(index) {
      console.log('attempt', this.editing.buttons[index])
      console.log('new', this.defaultButton)
      this.editing.buttons = this.editing.buttons.map((button, butIndex) => {
        if (index == butIndex)
          button = this.defaultButton
        return button
      })
    },
    apiCallGet(event) {
      if (event)
        event.preventDefault()
      fetch(`${window.api_endpoint}/api/votes/${this.searchTermGuildId}`, {
        headers: {
          authorization: `Bearer ${this.apiKey}`
        }
      }).then(r => r.json()).then(j => {
        this.items = j
        localStorage.setItem("LAST_SEARCH_GUILD_ID", this.searchTermGuildId)
      })
    },
    async apiCallDelete(event) {
      if (event)
        event.preventDefault()
      let res = await fetch(`${window.api_endpoint}/api/votes/${this.searchTermGuildId}/${this.editing.vote_id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${this.apiKey}`
        }
      })

      this.understandDeleting = false;

      if (res.status == 200) {
        this.apiCallGet()
        this.$bvToast.toast(`Successfully deleted '${this.editing.vote_id}'`, {
          title: 'Delete Vote',
          autoHideDelay: 5000,
          appendToast: true
        })
        // delete-vote-prompt-modal
        this.$bvModal.hide("delete-vote-prompt-modal")
        return this.$bvModal.hide("edit-vote-modal")
      }

      if (res.status == 400) {
        let json = await res.json()
        this.$bvToast.toast(`Failed to delete '${this.editing.vote_id}': ${json.message}`, {
          title: 'Delete Vote',
          autoHideDelay: 5000,
          appendToast: true
        })
        return;
      }

      if (res.status != 204) {
        let json = await res.json()
        this.$bvToast.toast(`Failed to delete '${this.editing.vote_id}': ${json.message}`, {
          title: 'Delete Vote',
          autoHideDelay: 5000,
          appendToast: true
        })
      }
      return false
    },
    async apiCallUpdate(event) {
      if (event)
        event.preventDefault()
      let res = await fetch(`${window.api_endpoint}/api/votes/${this.searchTermGuildId}/${this.editing.vote_id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${this.apiKey}`
        },
        body: JSON.stringify(this.editing)
      })
      if (res.status == 200) {
        this.apiCallGet()
        this.$bvToast.toast(`Successfully saved changes for '${this.editing.vote_id}'`, {
          title: 'Edit Vote',
          autoHideDelay: 5000,
          appendToast: true
        })
        return this.$bvModal.hide("edit-vote-modal")
      }

      if (res.status == 400) {
        let json = await res.json()
        this.$bvToast.toast(`Failed to save changes for '${this.editing.vote_id}': ${json.message}`, {
          title: 'Edit Vote',
          autoHideDelay: 5000,
          appendToast: true
        })
        if (json.missingFields)
          this.$bvToast.toast(`Missing fields: ${json.missingFields.join(", ")}`, {
            title: 'Edit Vote',
            autoHideDelay: 5000,
            appendToast: true
          })
        return;
      }

      if (res.status != 204) {
        let json = await res.json()
        this.$bvToast.toast(`Failed to save changes for '${this.editing.vote_id}': ${json.message}`, {
          title: 'Edit Vote',
          autoHideDelay: 5000,
          appendToast: true
        })
      }
      return false
    },
    async apiCallPost(event) {
      if (event)
        event.preventDefault()
      let res = await fetch(`${window.api_endpoint}/api/votes/${this.searchTermGuildId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${this.apiKey}`
        },
        body: JSON.stringify(this.editing)
      })
      if (res.status == 200) {
        this.apiCallGet()
        this.$bvToast.toast(`Successfully created vote '${this.editing.vote_id}' in ${this.editing.guild_id}`, {
          title: 'Create Vote',
          autoHideDelay: 5000,
          appendToast: true
        })
        return this.$bvModal.hide("edit-vote-modal")
      }

      if (res.status == 400) {
        let json = await res.json()
        this.$bvToast.toast(`Failed to create '${this.editing.vote_id}' vote: ${json.message}`, {
          title: 'Create Vote',
          autoHideDelay: 5000,
          appendToast: true
        })
        if (json.missingFields)
          this.$bvToast.toast(`Missing fields: ${json.missingFields.join(", ")}`, {
            title: 'Create Vote',
            autoHideDelay: 5000,
            appendToast: true
          })

        return;
      }

      if (res.status != 204) {
        let json = await res.json()
        this.$bvToast.toast(`Failed to create '${this.editing.vote_id}' vote: ${json.message}`, {
          title: 'Create Vote',
          autoHideDelay: 5000,
          appendToast: true
        })
      }
      return false
    },
    buttonCustomIdFormatter(value) {
      if (value.trim() == '')
        return ''
      if (value.startsWith("vote_"))
        return value;
      return `vote_${value}`
    },
    voteIdFormatter(value) {
      if (value?.trim() == '')
        return ''
      if (value.startsWith("vote_"))
        return value?.replace("vote_", "")?.trim();
      return value
    },
    handleSubmit(event) {
      if (this.currentEditingState == "EDITING") {
        this.apiCallUpdate(event)
      } else if (this.currentEditingState == "NEW") {
        this.apiCallPost(event)
      }
    },
    setSettings(event) {
      event.preventDefault()
      this.$nextTick().then(() => {
        localStorage.setItem("API_KEY", this.apiKey)
        localStorage.setItem("DEFAULT_WEBHOOK", this.defaultWebhook)
        localStorage.setItem("DEFAULT_BUTTON", JSON.stringify(this.defaultButton))
        this.$bvModal.hide("set-apikey-modal")
      })
    },
    addVoteModal() {
      this.editingState = "NEW"
      this.editing = {
        'active': false,
        'voteCount': 0,
        'buttons': [
          {
            alreadyVotedMessage: "",
            custom_id: "",
            label: "",
            message: ""
          },
          {
            alreadyVotedMessage: "",
            custom_id: "",
            label: "",
            message: ""
          }
        ],
        'discord_webhook': "",
        'guild_id': "",
        'initialMessage': "",
        'vote_id': "",
        'anonymizeResponses': false,
        'anonymizeResults': false
      };
      this.$bvModal.show("edit-vote-modal")
    }
  },
  computed: {
    currentItems() {
      return this.searchTerm ? this.items.filter(q =>
        q.initialMessage.toLowerCase().includes(this.searchTerm.trim().toLowerCase())
        || q.id.includes(this.searchTerm.trim().toLowerCase())
        || q.guild_id && q.guild_id.includes(this.searchTerm.trim().toLowerCase()))
        : this.items
    },
    currentEditingState() {
      return this.editingState
    },
    runningBuildPatchStatus() {
      return this.$cookies.get("build_patch") && this.$cookies.get("build_patch") != ''
    },
    runningBuildPatch() {
      return this.$cookies.get("build_patch") || false
    },
    isInvalidBuild() {
      return this.invalidBuild
    },

    defaultButtonExists() {
      return localStorage.getItem("DEFAULT_BUTTON") && localStorage.getItem("DEFAULT_BUTTON") != '' ? true : false
    }
  },
  created() {
    this.apiKey = localStorage.getItem("API_KEY") || "";
    this.defaultWebhook = localStorage.getItem("DEFAULT_WEBHOOK") || "";
    if (localStorage.getItem("DEFAULT_BUTTON"))
      this.defaultButton = JSON.parse(localStorage.getItem("DEFAULT_BUTTON"));
    this.searchTermGuildId = localStorage.getItem("LAST_SEARCH_GUILD_ID") || ""
    if (typeof this.searchTermGuildId == "string" && this, this.searchTermGuildId.trim())
      this.apiCallGet()
    if (this.runningBuildPatchStatus) {
      this.checkBuildPatch()
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
